import axios from 'axios';
import { PROJECTS } from '../../../consts/Projects';
import { Web3GameService } from '../../../services/Web3GameService';
import { API_URL } from '../consts/GameUrl';
import { HOUSE_TRAITS_CONFIG } from '../consts/HouseTraits';
import { SessionManager } from '../manager/SessionManager';
import lodash from 'lodash';
import { GAME_MESSAGES } from '../../../consts/Messages';
import { GameUtils } from '../Utils/GameUtils';
import { METAMASK_ERROR_CODE } from '../../../consts/Web3Consts';
export class DataService {
  constructor() {
    /**
     * The amount of coins the player has.
     * @type {number}
     */

    this.coins = 0;
    this.currentGameDay = 0;
    this.hauntedCounter = 0;
    this.mission_counter = 0;
    this.owned_traits = [];
    this.active_traits = lodash.cloneDeep(HOUSE_TRAITS_CONFIG);
    this.available_traits = [];
    this.specific_haunt_nfts_project = {};
    /**
     * ID of the owned traits
     */
    this.owned_traits_ids = null;
    /**
     * ID of the active traits
     */
    this.active_traits_ids = null;
    this.main_song = null;
    this.house_information = null;
    this.mute = false;
    this.createAPI();
    this.web3GameService = new Web3GameService();
    this.session = new SessionManager();
    this.should_reload_data = false;
    if (this.constructor.instance) {
      return this.constructor.instance;
    }
    this.constructor.instance = this;
  }

  clearData() {
    this.coins = 0;
    this.currentGameDay = 0;
    this.hauntedCounter = 0;
    this.mission_counter = 0;
    this.owned_traits = [];
    this.active_traits = lodash.cloneDeep(HOUSE_TRAITS_CONFIG);
    this.available_traits = [];
    this.specific_haunt_nfts = [];
    /**
     * ID of the owned traits
     */
    this.owned_traits_ids = null;
    /**
     * ID of the active traits
     */
    this.active_traits_ids = null;
    this.main_song = null;
    this.house_information = null;
    this.mute = false;
    this.should_reload_data = false;
  }

  createAPI() {
    this.api = axios.create({
      // withCredentials: true,
      baseURL: API_URL + '/api/',
    });
    this.api.interceptors.request.use((config) => {
      config.headers.post['X-Oni-Auth-Token'] = this.authToken;
      config.headers.get['Authorization'] = `Bearer ${this.session.sessionToken || ''}`;
      config.headers.post['Authorization'] = `Bearer ${this.session.sessionToken || ''}`;
      return config;
    });

    this.api.interceptors.response.use(
      (response) => {
        return response;
      },
      (rejected) => {
        if (rejected?.response?.status === 401) {
          window.location.href = `#/oni-mansion-game/?error_code=401`;
          window.location.reload();
        }
        return rejected;
      }
    );
  }

  setAuthToken(authToken) {
    this.authToken = authToken;
  }

  async getWeb3Config() {
    try {
      await this.web3GameService.getConfig();
      await this.getCurrentGameDay();
    } catch (error) {
      return error;
    }
  }

  async authenticateJwt() {
    const publicAddress = this.web3GameService.account;
    const result = await this.api.get(`/metamask/nonce/${publicAddress}`);
    let signature = await this.web3GameService.signNonce(publicAddress, result.data.nonce);
    let { data } = await this.api.post(`/metamask/authenticate`, { publicAddress, signature });
    this.session.setSessionToken(data.token);
  }

  /**
   *
   * @param {Array<number>} ids
   * @returns Array of onis.
   */
  async [PROJECTS.ONIS.key](ids) {
    try {
      const { data } = await this.api.get(`onis/${ids.join(',')}`);
      return { contract_address: PROJECTS.ONIS.contract_address, type: PROJECTS.ONIS.key, data };
    } catch (err) {
      throw err;
    }
  }

  async [PROJECTS.LAZY_LIONS.key](ids) {
    try {
      const { data } = await this.api.get(`lions/${ids.join(',')}`);
      return { contract_address: PROJECTS.LAZY_LIONS.contract_address, type: PROJECTS.LAZY_LIONS.key, data };
    } catch (err) {
      throw err;
    }
  }

  async [PROJECTS.CURIOUS_ADDYS.key](ids) {
    try {
      const { data } = await this.api.get(`addys/${ids.join(',')}`);
      return { contract_address: PROJECTS.CURIOUS_ADDYS.contract_address, type: PROJECTS.CURIOUS_ADDYS.key, data };
    } catch (err) {
      throw err;
    }
  }
  async [PROJECTS.JUNGLE_FREAKS.key](ids) {
    try {
      const { data } = await this.api.get(`jungle-freaks/${ids.join(',')}`);
      return { contract_address: PROJECTS.JUNGLE_FREAKS.contract_address, type: PROJECTS.JUNGLE_FREAKS.key, data };
    } catch (err) {
      throw err;
    }
  }

  async [PROJECTS.BABY_SPIRITS.key](ids) {
    try {
      const { data } = await this.api.get(`baby-spirits/${ids.join(',')}`);
      return { contract_address: PROJECTS.BABY_SPIRITS.contract_address, type: PROJECTS.BABY_SPIRITS.key, data };
    } catch (err) {
      throw err;
    }
  }
  // async [PROJECTS.MIRROR.key](ids) {
  //   try {
  //     const { data } = await this.api.get(`mirror-worlds/${ids.join(',')}`);
  //     return { contract_address: PROJECTS.MIRROR.contract_address, type: PROJECTS.MIRROR.key, data };
  //   } catch (err) {
  //     throw err;
  //   }
  // }

  setSelectedCharacter(character) {
    this.selected_character = character;
    sessionStorage.setItem('selected_character', JSON.stringify(character));
  }

  async getCoins() {
    this.coins = await this.web3GameService.getOniCoinBalance(this.web3GameService.account);
    return this.coins;
  }

  /**
   * Returns the owned house attributes
   */
  async getHouseAttributes() {
    let oniItems = await this.web3GameService.getHouseAttrs(this.session?.contractAddress, this.session?.nft?.id);
    const owned_traits_ids = oniItems.split(',');
    // if (oniItems) {
    //   oniItems = oniItems.split(',');
    //   oniItems.forEach((owned) => {
    //     const foundItem = this.available_traits.find((t) => parseInt(t.id) === parseInt(owned));
    //     if (foundItem) this.owned_traits.push(foundItem);
    //   });
    // }
    return owned_traits_ids;
  }

  async checkMissingAttributes() {
    const owned_attributes = await this.getHouseAttributes();
    const ids = lodash.difference(owned_attributes, this.owned_traits_ids).filter((id) => id !== '');
    if (ids?.length > 0 && this.house_information) {
      console.log('Update Missing Attributes!');
      const update_missing = {
        ids,
        house_id: this.house_information.id,
      };
      this.should_reload_data = true;
      await this.api.post(`/houses/update-missing`, update_missing);
    }
  }

  /**
   * Gets the Currently equiped house attributes.
   */
  async getEquipedHouseAttributes(contract_address = this.session.contractAddress, nft_id = this.session.nft.id) {
    if (this.should_reload_data || !this.active_traits || !this.active_traits_ids) {
      this.active_traits_ids = [];
      this.active_traits = lodash.cloneDeep(HOUSE_TRAITS_CONFIG);

      const { active_traits, active_traits_ids } = await this.getEquipedHouseAttributesDetached(
        contract_address,
        nft_id
      );
      this.active_traits = active_traits;
      this.active_traits_ids = active_traits_ids;
      return Promise.resolve(this.active_traits);
    } else {
      return Promise.resolve(this.active_traits);
    }
  }

  async getEquipedHouseAttributesDetached(contract_address, nft_id) {
    const active_traits_ids = [];
    const active_traits = lodash.cloneDeep(HOUSE_TRAITS_CONFIG);
    const queries = [];
    const keys = [];
    return new Promise(async (resolve, reject) => {
      Object.keys(active_traits).forEach((key, index) => {
        const slot = (index + 1).toString();
        keys.push(key);
        queries.push(this.web3GameService.nftToHouseSlotToAttrID(contract_address, nft_id, slot));
      });
      Promise.all(queries).then((response) => {
        response.forEach((ID, resIndex) => {
          active_traits_ids.push(ID);
          const item = this.available_traits.find((t) => t.id === ID);
          if (item) active_traits[keys[resIndex]] = item.name;
        });
        resolve({ active_traits, active_traits_ids });
      });
    });
  }

  async getHouseAttributeDescription(id) {
    return await this.api.get(`/houses/attribute-description/${id}`);
  }

  /**
   * Buys an Atribute to the house.
   * @param { string } attributeId
   * @param { string } value
   */
  async buyHouseAttribute(attributeId, value) {
    const response = await this.web3GameService.buyHouseAttrs(
      this.session.contractAddress,
      this.session.nft.id,
      attributeId,
      value
    );
    return response;
  }

  /**
   * Equips the item to a specific slot
   * @param { string } id
   * @param { string } slot
   */
  async equipHouseAttribute(id, slot) {
    try {
      await this.web3GameService.equipAttrToHouse(this.session.contractAddress, this.session.nft.id, id, slot);
    } catch (err) {
      console.error(err);
    }
  }

  async getHouseInformation(contract_address = this.session.contractAddress, nft_id = this.session.nft.id) {
    try {
      if (!this.house_information || this.should_reload_data) {
        const { data } = await this.api.get(`/houses/info/${contract_address}/${nft_id}`);
        this.active_traits = lodash.cloneDeep(HOUSE_TRAITS_CONFIG);
        if (data && !data.code) {
          this.house_information = data;
          this.owned_traits = data.attributes;
          this.active_traits_ids = [];
          this.owned_traits_ids = [];
          this.owned_traits?.forEach((value) => {
            if (value.active) {
              this.active_traits_ids.push(value.id);
              this.active_traits[value.trait_type] = value.name;
            }
            this.owned_traits_ids.push(value.id);
          });
        }
      }
      // console.log('OWNED TRAITS');
      // console.table(this.owned_traits.map((t) => ({ id: t.id, active: t.active, slot: t.trait_type_code })));
      // console.log('ACTIVE TRAITS');
      // console.table(
      //   this.owned_traits.filter((f) => f.active).map((t) => ({ id: t.id, active: t.active, slot: t.trait_type_code }))
      // );
      return this.active_traits;
    } catch (err) {
      throw err;
    }
  }

  async getAvailableTraits() {
    try {
      if (this.should_reload_data || this.available_traits.length === 0) {
        const { data } = await this.api.get('/houses/attributes/all');
        this.available_traits = data;
        return this.available_traits;
      } else {
        return this.available_traits;
      }
    } catch (err) {
      throw err;
    }
  }

  /**
   * Creates a new trait and adds it to the owned traits.
   * @param { any } item house attributes.
   * @returns
   */
  async addHouseAttributes(item) {
    try {
      const { data } = await this.api.post('/houses/add-house-attribute', {
        attribute_id: item.id,
        house_id: this.house_information.id,
        contract_address: this.session.contractAddress,
        trait_type_code: item.trait_type_code,
        nft_id: this.session.nft.id,
      });
      // console.log(data);
      if (!data.code) {
        await this.getHouseInformation();
      }
      return data;
    } catch (err) {
      throw err;
    }
  }

  /**
   * Updates all the Equiped House Attributes.
   * @returns
   */
  async updateEquipedAttributes() {
    try {
      const { data } = await this.api.post('/houses/update/equipped/bulk', {
        house_id: this.house_information.id,
        attributes: this.owned_traits,
      });
      if (!data.code) {
        await this.getHouseInformation();
      }
      return data;
    } catch (err) {
      throw err;
    }
  }

  async setNFTOwnership(nft_id, contract_address, wallet) {
    try {
      const { data } = await this.api.post('/contract/set-nft-ownership', { nft_id, contract_address, wallet });
      return data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  }
  async getNFTOwnership(wallet) {
    try {
      const { data } = await this.api.get('/contract/get-nft-ownership', { params: { wallet } });
      return await this.parseNFTProjects(data);
    } catch (err) {
      console.error(err);
      return err;
    }
  }

  async parseNFTProjects(projects) {
    const promises = [];
    Object.keys(PROJECTS).forEach((high_key) => {
      const key = PROJECTS[high_key].key;
      if (projects[key] && projects[key].length > 0) {
        promises.push(this[key](projects[key]));
      }
    });
    const data = {};
    const promise_result = await Promise.all(promises);
    promise_result.map((item) => {
      data[item.type] = item.data;
    });
    return data;
  }

  /**
   * Checks if the player has approved the store for transactions.
   * @param { Phaser.Scene } scene
   */
  async checkApprovedStore(scene) {
    if (!this.approved_store || this.should_reload_data) {
      const approved = await this.web3GameService.checkApprovedToStore();
      scene.transactionGif.setVisible(!approved);
      if (!approved) {
        scene.add.modalMessage(GAME_MESSAGES.APPROVE_STORE_TRANSACTIONS);
        const approve_result = await this.web3GameService.approveToStore();
        scene.transactionGif.setVisible(false);
        this.approved_store = approve_result;
        return approve_result;
      }
      return approved;
    } else {
      return this.approved_store;
    }
  }

  async lastDailyCheckIn() {
    await this.getCurrentGameDay();
    // console.log(this.currentGameDay);
    if (this.session?.contractAddress && this.session?.nft?.id) {
      return await this.web3GameService.nftLastCheckIn(
        this.session?.contractAddress,
        this.session?.nft?.id,
        this.currentGameDay
      );
    } else {
      return true;
    }
  }

  /**
   * Returns if a player can do the daily checkin. TRUE Means he can Check-IN.
   * @returns {boolean}
   */
  async canDailyCheckIn() {
    await this.getCurrentGameDay();
    const hasCheckedIn = await this.lastDailyCheckIn();
    const { current_day } = GameUtils.currentGameDay();
    console.log(this.currentGameDay, current_day, hasCheckedIn);
    if ((parseInt(this.currentGameDay) === current_day && hasCheckedIn) || current_day < 0) {
      return false;
    } else {
      return true;
    }
  }

  async doDailyCheckIn() {
    try {
      const response = await this.web3GameService.doDailyCheckIn(this.session.contractAddress, this.session.nft.id);
      this.currentGameDay = await this.web3GameService.currentDayInt();
      return response;
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async getCurrentGameDay() {
    this.currentGameDayUnix = await this.web3GameService.getCurrentDayUnix();
    this.currentGameDay = await this.web3GameService.currentDayInt();
  }

  async hasGenericHaunt(contract_address = this.session?.contractAddress, nft_id = this.session?.nft?.id) {
    if (contract_address && nft_id) {
      return await this.web3GameService.hasGenericHaunt(contract_address, nft_id, this.currentGameDay);
    } else {
      return false;
    }
  }
  async hasSpecificHaunt(contract_address = this.session?.contractAddress, nft_id = this.session?.nft?.id) {
    if (contract_address && nft_id) {
      return await this.web3GameService.hasSpecificHaunt(contract_address, nft_id, this.currentGameDay);
    } else {
      return false;
    }
  }
  async claimedDailyBonusToday(contract_address = this.session?.contractAddress, nft_id = this.session?.nft?.id) {
    return await this.web3GameService.claimedDailyBonusToday(contract_address, nft_id, this.currentGameDay);
  }
  async claimedWeeklyBonusToday(contract_address = this.session?.contractAddress, nft_id = this.session?.nft?.id) {
    return await this.web3GameService.claimedWeeklyBonusToday(contract_address, nft_id, this.currentGameDay);
  }
  async getHauntedCountContract(contract_address = this.session?.contractAddress, nft_id = this.session?.nft?.id) {
    this.hauntedCounter = await this.web3GameService.getTimesHauntedNft(contract_address, nft_id);
    return this.hauntedCounter;
  }
  async claimDailyBonus(contract_address = this.session?.contractAddress, nft_id = this.session?.nft?.id) {
    await this.web3GameService.claimDailyBonus(contract_address, nft_id);
  }
  async claimWeeklyBonus(contract_address = this.session?.contractAddress, nft_id = this.session?.nft?.id) {
    await this.web3GameService.claimWeeklyBonus(contract_address, nft_id);
  }

  async getMissionCounter() {
    this.mission_counter = await this.web3GameService.missionCounter(this.session.contractAddress, this.session.nft.id);
    return this.mission_counter;
  }

  async openLootbox() {
    // console.log('OPEN!!!');
    try {
      return await this.web3GameService.openLootBox(this.session.contractAddress, this.session.nft.id);
    } catch (error) {
      console.log(error);
      return error;
    }
  }

  async checkSessionCandles(scene) {
    const { data } = await this.api.get('/auth/check-session', { params: { ...GameUtils.getUrlParams() } });
    // console.log(data);
    if (!data.success) {
      scene.add.modalMessage(GAME_MESSAGES.LOGIN_REQUIRED);
      window.open(
        `${API_URL}/api/auth/google`,
        '',
        'scrollbars=yes,menubar=no,width=500, resizable=yes,toolbar=no,location=no,status=no'
      );
      return false;
    } else {
      return true;
    }
  }

  async lightCandle() {
    const { data } = await this.api.get('/haunt/light-candle', { params: { ...GameUtils.getUrlParams() } });
    return data;
  }
  async getCandleLightCounter(contract_address = this.session.contractAddress, nft_id = this.session.nft.id) {
    const { data } = await this.api.get('/haunt/light-candle/count', { params: { contract_address, nft_id } });
    this.candle_count = data;
    return data;
  }

  async checkNFTOwnership(contract_address, nft_id) {
    const wallet_address = await this.web3GameService.mappedNFTToOwner(contract_address, nft_id);
    return { owns: wallet_address === this.web3GameService.account, wallet_address };
  }

  async genericHauntHouse(victimContractAddress, victimNftId, message) {
    try {
      const currentGameDay = await this.web3GameService.currentDayInt();
      const response = await this.web3GameService.hauntGenericOni(
        this.session.contractAddress,
        this.session.nft.id,
        victimContractAddress,
        victimNftId,
        currentGameDay
      );
      if (!response.code) {
        await this.createHaunt(victimContractAddress, victimNftId, message);
      }
      return response;
    } catch (err) {
      throw err;
    }
  }

  async specificHauntHouse(victimContractAddress, victimNftId, message) {
    try {
      const currentGameDay = await this.web3GameService.currentDayInt();
      const response = await this.web3GameService.hauntSpecificNFT(
        this.session.contractAddress,
        this.session.nft.id,
        victimContractAddress,
        victimNftId,
        currentGameDay
      );
      if (!response.code) {
        await this.createHaunt(victimContractAddress, victimNftId, message);
      }
      return response;
    } catch (err) {
      throw err;
    }
  }

  async createHaunt(victimContractAddress, victimNftId, message) {
    const body = {
      haunter_contract_id: this.session.contractAddress,
      haunter_id: this.session.nft.id,
      hauntee_contract_id: victimContractAddress,
      hauntee_id: victimNftId,
      message,
    };
    try {
      await this.api.post('/haunt/create', body);
    } catch (err) {
      console.error(err);
      throw err;
    }
  }

  async getReceivedMessages() {
    const params = {
      hauntee_id: this.session.nft.id,
      hauntee_contract_id: this.session.contractAddress,
    };
    const { data } = await this.api.get(`${process.env.VUE_APP_SERVER_URI}/api/haunt/get-hauntee-messages`, { params });
    return data;
  }

  updateMessage(message) {
    this.api.post('haunt/update-message', message);
  }

  async getSpecificHauntMissionNFTIDs() {
    if (this.specific_haunt_nfts?.length === 0 || this.should_reload_data) {
      let nfts = [];
      let requests = [];
      const projects = Object.entries(PROJECTS).map((m) => m[1]);
      for await (const project of projects) {
        const nft_ids = await this.web3GameService.specificHauntMissionNFTIDs(project.contract_address);
        this.specific_haunt_nfts_project[project.contract_address] = nft_ids;
        if (nft_ids && nft_ids.length > 0) {
          requests.push(this[project.key](nft_ids));
        }
      }
      let projects_response = await Promise.all(requests);
      projects_response.forEach((promise_data) => {
        const d = promise_data.data
          .filter((f) => {
            if (f.id != this.session.nft.id && promise_data.contract_address === this.session.contractAddress) {
              return true;
            } else if (promise_data.contract_address != this.session.contractAddress) {
              return true;
            }
          })
          .map((m) => ({ ...m, project_name: promise_data.type, contract_address: promise_data.contract_address }));
        nfts = [...nfts, ...d];
      });
      this.specific_haunt_nfts = nfts;
    }
    return this.specific_haunt_nfts;
  }

  async getGenericHauntNFTs() {
    await this.getCurrentGameDay();
    const { data } = await this.api.get('haunt/generic-haunt-nfts', { params: { game_day: this.currentGameDay } });
    return (
      (!data?.code &&
        data.filter((f) => {
          if (f.id != this.session.nft.id && f.contract_address === this.session.contractAddress) {
            return true;
          } else if (f.contract_address != this.session.contractAddress) {
            return true;
          }
        })) ||
      null
    );
  }

  checkAlreadyHasAttribute(attribute) {
    if (this.active_traits[attribute.trait_type]) return true;
    else return false;
  }

  async checkNetwork() {
    return await this.web3GameService.checkNetwork();
  }

  updateInteractedContract() {
    this.api.post('/haunt/update-interacted-contract', {
      contract_address: this.session.contractAddress,
      nft_id: this.session.nft.id,
    });
  }
  async getHouseAttributesContract() {
    const { data } = await this.api.get('/houses/attributes/contract-list');
    return data;
  }

  async interactedNFTToOwner(contract_address = this.session.contractAddress, nft_id = this.session.nft.id) {
    try {
      const interacted = await this.web3GameService.interactedNFTToOwner(contract_address, nft_id);
      if (interacted === '0x0000000000000000000000000000000000000000') {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async getDailyAsks() {
    try {
      await this.getCurrentGameDay();
      const { data } = await this.api.get(
        `lores?game_day=${this.currentGameDay}&wallet=${this.web3GameService.account}`
      );
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async updateClickedLore(lore_id) {
    try {
      const { data } = await this.api.post(`lores/update-clicked`, {
        lore_id,
        wallet: this.web3GameService.account,
      });
      return data;
    } catch (error) {
      console.log(error);
      return false;
    }
  }
}
