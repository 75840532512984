import { Button } from '../components/Button';
import { ONI_TRAITS } from '../consts/OniTraits';
import Phaser from 'phaser';
import { PROJECTS } from '../../../consts/Projects';
import GameConfig from '../../../../migrations/HouseGameConfig.json';
import { PRODUCTION_NETWORK, STAGING_NETWORK, TEST_NETWORK } from '../consts/Network';

export class GameUtils {
  static zeroPad(num, places) {
    return String(num).padStart(places, '0');
  }

  /**
   * Calculates the proper height of an image with a custom width, preserving the original aspect ratio.
   *
   * @param originalHeight
   * @param originalWidth
   * @param newWidth
   */
  static determineNewHeight(originalHeight, originalWidth, newWidth) {
    return (originalHeight / originalWidth) * newWidth;
  }

  /**
   * Calculates the proper height of an image with a custom width, preserving the original aspect ratio.
   *
   * @param originalHeight
   * @param originalWidth
   * @param newWidth
   */
  static determineNewHeight(originalHeight, originalWidth, newWidth) {
    return (originalHeight / originalWidth) * newWidth;
  }

  static addButtonsGroup(scene, buttonList, behaviorList) {
    const btns = [];
    Object.entries(buttonList).forEach(([key, layout]) => {
      const button = new Button(
        scene,
        {
          x: layout.POSITION.X,
          y: layout.POSITION.Y,
          width: layout.SIZE.WIDTH,
          height: layout.SIZE.HEIGHT,
        },
        key
      );
      button.onClick(behaviorList[key].bind(scene));
      btns.push(button);
    });
    return btns;
  }
  /**
   * Maps the API oni traits to the oni entity pattern.
   * @param {any} traits
   */
  static mapOniApiTraits(oni) {
    const result = {};
    ONI_TRAITS.forEach((trait) => {
      result[trait] = oni.attributes.find((t) => t.trait_type === trait).value;
    });
    return result;
  }
  /**
   * Short method to run a grid bidimensional array behavior without hadouken tabs (lol)
   * @param {number} rows
   * @param {number} cols
   * @param {Function} fn
   */
  static matrix(rows, cols, fn) {
    for (let i = 0; i < cols; i++) {
      for (let j = 0; j < rows; j++) {
        fn(i, j);
      }
    }
  }

  /**
   * Get the area center according to provided dimensions and sizes. Very uselful to centralize a {GameObject} dynamically.
   * @param {number} x
   * @param {number} y
   * @param {number} width
   * @param {number} height
   * @param {Phaser.Scene} scene
   * @returns {Phaser.Math.Vector2}
   */
  static getAreaCenter(x, y, width, height, scene) {
    const zone = scene.add.zone(x, y, width, height).setOrigin(0, 0);
    const ref = zone.getCenter();
    zone.destroy();
    return ref;
  }

  /**
   * Changes the folder name to one that any OS Understands.
   * @param { string } name
   * @returns Folder name without spaces and capital letters.
   */
  static folderName(name) {
    return name
      .toLowerCase()
      .trim()
      .split(' ')
      .join('_');
  }

  static getHouseTier(OBJ, count) {
    const tier = Object.keys(OBJ).find((key) => {
      const [min, max] = key.split('...').map((m) => parseInt(m));
      return parseInt(min) <= parseInt(count) && parseInt(count) <= parseInt(max);
    });
    return tier ? OBJ[tier] : false;
  }

  static getUrlParams() {
    const path = window.location.href.split('#')[1].split('/');
    const url = path[1];
    const contract_address = path[2];
    const nft_id = path[3];

    return { url, contract_address, nft_id };
  }

  /**
   * Get container size dynamically cause Phaser don't provide that
   * @param {Phaser.GameObjects.Container} container
   * @return {Phaser.Structs.Size}
   */
  static getContainerSize(container) {
    let top = container.scene.game.config.height,
      left = container.scene.game.config.width,
      bottom = 0,
      right = 0;
    container.iterate((child) => {
      let childX = child.x;
      let childY = child.y;
      let childW = child.displayWidth;
      let childH = child.displayHeight;
      let childTop = childY - childH * child.originY;
      let childBottom = childY + childH * (1 - child.originY);
      let childLeft = childX - childW * child.originX;
      let childRight = childX + childW * (1 - child.originY);
      if (childBottom > bottom) {
        bottom = childBottom;
      }
      if (childTop < top) {
        top = childTop;
      }
      if (childLeft < left) {
        left = childLeft;
      }
      if (childRight > right) {
        right = childRight;
      }
    });
    return new Phaser.Structs.Size(Math.abs(right - left), Math.abs(top - bottom));
  }

  /* Randomize array in-place using Durstenfeld shuffle algorithm */
  static shuffleArray(array) {
    if (array?.length > 0) {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
    }
  }

  static getProject(contract_address) {
    return Object.entries(PROJECTS)
      .filter(([key, value]) => {
        if (value.contract_address === contract_address) {
          return value;
        }
      })
      .map((m) => m[1])[0];
  }

  static getContractNFTID(url) {
    const [contract_address, nft_id] = url.split('#/oni-mansion-game/')[1].split('/');
    return { contract_address, nft_id };
  }

  static pointerTimeCheck(pointer) {
    return pointer.upTime - pointer.downTime < 300;
  }

  /**
   * Calculates the current game day based of the current time and starting game time.
   */
  static currentGameDay() {
    const current_time = parseInt(
      new Date()
        .getTime()
        .toString()
        .substring(0, 10)
    );
    const start = parseInt(GameConfig.GAME_START_UNIX);
    const game_day_length = parseInt(GameConfig.GAME_DAY_LENGTH_UNIX);
    const time_game_rolling = current_time - start;
    const current_day = time_game_rolling / game_day_length;
    const int_part = Math.trunc(current_day); // returns 3
    const float_part = Number((current_day - int_part).toFixed(2)); // ret
    const time_left_ms = parseInt((1 - float_part) * game_day_length * 1000);
    return { current_day: Math.floor(current_day), current_day_fraction: current_day, time_left_ms };
  }

  static getHoursMinutesEndDay(duration) {
    let seconds = Math.floor((duration / 1000) % 60),
      minutes = Math.floor((duration / (1000 * 60)) % 60),
      hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;

    return hours + ':' + minutes;
  }

  static async switchNetworks() {
    const env = process.env.VUE_APP_GAME_ENVIRONMENT;
    if (window?.ethereum) {
      await ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [env === 'production' ? PRODUCTION_NETWORK : env === 'staging' ? STAGING_NETWORK : TEST_NETWORK],
      });
    }
  }
}
