// DO NOT CHANGE THE ORDER OF THIS LIST.
export const HOUSE_TRAITS_CONFIG = {
  'Side Window': null,
  'Roof Window': null,
  'First Front Window': null,
  'Second Front Window': null,
  'Tower Window': null,
  Door: null,
  Chimney: null,
  Roof: null,
  Yard: null,
};

// Correct order of creation of the Attributes.
export const HOUSE_CREATION_ORDER = {
  Roof: null,
  Chimney: null,
  'Roof Window': null,
  'Tower Window': null,
  'Second Front Window': null,
  'First Front Window': null,
  'Side Window': null,
  Door: null,
  Yard: null,
};
export const HOUSE_CREATION_POSITION = {
  Roof: {
    x: 0,
    y: 0,
  },
  Chimney: {
    x: 0,
    y: 0,
  },
  'Roof Window': {
    x: 0,
    y: 0,
  },
  'Tower Window': {
    x: 0,
    y: 0,
  },
  'Second Front Window': {
    x: 0,
    y: 0,
  },
  'First Front Window': {
    x: 3,
    y: 0,
  },
  'Side Window': {
    x: 3,
    y: 0,
  },
  Door: {
    x: 0,
    y: 0,
  },
  Yard: {
    x: 0,
    y: 0,
  },
};
