import Phaser from 'phaser';
import { SoundManager } from '../manager/SoundManager';

export class Button extends Phaser.GameObjects.Zone {
  constructor(scene, { x, y, width, height }, key) {
    super(scene, x, y, width, height);
    this.setInteractive();
    this.setOrigin(0, 0);
    this.uniqueKey = key;
    scene.add.existing(this);
  }

  onClick(callback) {
    this.on('pointerdown', () => {
      SoundManager.clickScreenSound(this.scene);
      callback();
    });
  }

  setEnabled(enable) {
    this.input.enabled = enable;
  }
}
