/**
 * @class
 * This class is used to play sounds.
 */
export class SoundManager {
  static clickScreenSound(scene, showAnimation = true) {
    scene.sound.play('click_screen');
    if (showAnimation) scene.add.clickEffect();
  }
}
