import { session } from 'passport';

export class SessionManager {
  constructor() {
    /**
     * The NFT Contract Address.
     */
    this.contractAddress =
      JSON.parse(
        typeof localStorage.getItem('contractAddress') !== 'undefined' ? localStorage.getItem('contractAddress') : null
      ) || null;

    /**
     * The NFT Token ID.
     */
    this.nft =
      JSON.parse(typeof localStorage.getItem('nft') !== 'undefined' ? localStorage.getItem('nft') : null) || null;
    /**
     * The last visited page.
     */
    this.lastPageVisited =
      JSON.parse(
        typeof localStorage.getItem('lastPageVisited') !== 'undefined' ? localStorage.getItem('lastPageVisited') : null
      ) || null;

    /**
     * The Session Token.
     */
    this.sessionToken =
      JSON.parse(
        typeof localStorage.getItem('sessionToken') !== 'undefined' ? localStorage.getItem('sessionToken') : null
      ) || null;

    if (this.constructor.instance) {
      return this.constructor.instance;
    }
    this.constructor.instance = this;
  }

  setContractAddress(contractAddress) {
    this.contractAddress = contractAddress;
    localStorage.setItem('contractAddress', JSON.stringify(contractAddress));
  }

  setNft(nft) {
    this.nft = nft;
    localStorage.setItem('nft', JSON.stringify(nft));
  }

  setSessionToken(sessionToken) {
    this.sessionToken = sessionToken;
    localStorage.setItem('sessionToken', JSON.stringify(sessionToken));
  }

  setLastPageVisited(lastPageVisited) {
    this.lastPageVisited = lastPageVisited;
    localStorage.setItem('lastPageVisited', JSON.stringify(lastPageVisited));
  }

  clearSession() {
    this.nft = null;
    this.contractAddress = null;
    localStorage.clear();
    this.setSessionToken(this.sessionToken);
  }
}
