export const ONI_TRAITS = [
  'Background',
  'Shoes',
  'Back',
  'Body Color',
  'Torso',
  'Hand',
  'Eyes',
  'Mouth',
  'Hat',
  'Neck',
];
