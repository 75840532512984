import Web3 from 'web3';

export const PRODUCTION_NETWORK = {
  chainId: Web3.utils.toHex('80001'),
  chainName: 'Polygon Mumbai',
  nativeCurrency: {
    name: 'MATIC',
    symbol: 'MATIC', // 2-6 characters long
    decimals: 18,
  },
  rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
  blockExplorerUrls: ['https://mumbai.polygonscan.com/'],
};

export const STAGING_NETWORK = PRODUCTION_NETWORK;

export const TEST_NETWORK = {
  chainId: Web3.utils.toHex('1337'),
  chainName: 'Ganache LOCAL',
  nativeCurrency: {
    name: 'ETH',
    symbol: 'ETH', // 2-6 characters long
    decimals: 18,
  },
  rpcUrls: ['http://127.0.0.1:7545'],
  blockExplorerUrls: ['https://polygonscan.com/'],
};
